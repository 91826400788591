import produce from "immer";

const getDefaultState = () => {
  return [];
};

const accounts = (state = getDefaultState(), action) => {
  let nextState;
  switch (action.type) {
    case "ACCOUNTS_GET":
      nextState = produce(state, (draftState) => {
        return action.payload.data.accounts;
      });
      return nextState;
    default:
      return state;
  }
};

export default accounts;
