import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { withRouter, Link } from "react-router-dom";
import Nav from "../Nav/Nav";
import MainMenu from "../../components/Menu/Menu";
import { Row, Col, Table } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { actionLeadsGet } from "../../actions/";
import "./Leads.css";

const mapStateToProps = (state, ownProps) => {
  if (state) {
    return state;
  } else {
    return null;
  }
};

const mapDispatchToProps = {
  actionLeadsGet,
};

class Leads extends Component {
  state = {
    loading: false,
    showLeadModal: false,
  };

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.getData();
    }
  }

  getData() {
    this.props.actionLeadsGet();
  }

  getColumns() {
    return [
      {
        title: "Organization",
        dataIndex: ["organization_name"],
        key: "organization_name",
        render: (text, result) => <Link to={`/lead/${result.id}`}>{text}</Link>,
      },
      {
        title: "Created",
        dataIndex: ["created_at"],
        key: "created",
        render: (text, result) => moment(text).format("MM-DD-YYYY"),
      },
    ];
  }

  render() {
    return (
      <div>
        <Nav url={"https://www.biobot.io/"} />
        <MainMenu {...this.props} />
        <div className="page">
          <Row gutter={20}>
            <Col sm={24}>
              <h2>Leads</h2>
              {this.state.loading && (
                <div className="view-loading">
                  <LoadingOutlined />
                </div>
              )}
            </Col>
          </Row>
          <Table
            columns={this.getColumns()}
            dataSource={this.props.leads}
            pagination={{
              defaultPageSize: 20,
              onChange: (value, item) => {
                console.log(value, item);
              },
              position: ["topRight", "bottomRight"],
            }}
          />
        </div>
      </div>
    );
  }
}

const LeadsContainer = connect(mapStateToProps, mapDispatchToProps)(Leads);
export default withRouter(LeadsContainer);
