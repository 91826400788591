import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Nav from "../Nav/Nav";
import MainMenu from "../../components/Menu/Menu";
import { Row, Col } from "antd";
import { getAccountData } from "./helpers.js";
import {
  actionUserGet,
  actionOrganizationGet,
  actionRolesGet,
} from "../../actions/";
import "./Account.css";

const mapStateToProps = (state, ownProps) => {
  if (state) {
    return state;
  } else {
    return null;
  }
};

const mapDispatchToProps = {
  actionUserGet,
  actionOrganizationGet,
  actionRolesGet,
};

class Account extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    getAccountData(this.props);
  }

  render() {
    return (
      <div>
        <Nav url={"https://www.biobot.io/"} />
        <MainMenu {...this.props} />
        <div className="page">
          <Row gutter={20}>
            <Col sm={24}>
              <h2>Account</h2>
            </Col>
            {this.props.organization.Orders &&
              this.props.organization.Orders.map((item, index) => (
                <Col key={index} span={24}>
                  <div className="order">
                    <div className="order-name">{item.OrderName}</div>
                    Status: Ordered | Order date: {item.created_at}
                  </div>
                </Col>
              ))}
          </Row>
        </div>
      </div>
    );
  }
}

const AccountContainer = connect(mapStateToProps, mapDispatchToProps)(Account);
export default withRouter(AccountContainer);
