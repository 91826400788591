import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import usAdmin from "../../data/us/admin.json";
import caAdmin from "../../data/ca/admin.json";
import Loader from "../../components/Loader/Loader";
import moment from "moment";
import { Row, Col, Input, Select, Modal, Form, Button, message } from "antd";
import "./LeadForm.css";
import {
  actionLeadGet,
  actionLeadsGet,
  actionOrganizationAdd,
  actionLeadDelete,
} from "../../actions/";
const { Option } = Select;
const mapStateToProps = (state, ownProps) => {
  if (state) {
    return state;
  } else {
    return null;
  }
};

const mapDispatchToProps = {
  actionLeadGet,
  actionLeadsGet,
  actionOrganizationAdd,
  actionLeadDelete,
};

class LeadForm extends Component {
  state = {
    loading: false,
    visible: true,
    country: "USA",
    lead: {},
  };
  formRef = React.createRef();

  componentDidMount() {
    this.props
      .actionLeadGet(this.props.match.params.id)
      .then((res) => {
        const formData = res.payload.data;
        this.setState({
          lead: res.payload.data,
        });
        this.formRef.current.setFieldsValue({
          Name: formData.lead_meta.contact_name,
          Email: formData.lead_meta.contact_email,
          Organization: formData.lead_meta.organization_name,
          Title: formData.lead_meta.contact_title,
          Phone: formData.lead_meta.contact_phone,
          Industry: formData.lead_meta.organization_industry,
          Province: formData.lead_meta.province,
          City: formData.lead_meta.city,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    return (
      <div>
        <Modal
          title=""
          width={"80%"}
          footer={null}
          visible={this.state.visible}
          onCancel={(e) => {
            this.props.history.push(`/leads${this.props.location.search}`);
          }}
        >
          {this.state.loading && <Loader />}
          <Form
            ref={this.formRef}
            layout="vertical"
            onFinish={(values) => {
              this.setState({
                loading: true,
              });
              this.props
                .actionOrganizationAdd({
                  organization_name: values["Organization"],
                  account_type: "government",
                  organization_meta: {
                    contact_name: values["Name"],
                    contact_email: `orderservice+${moment().format(
                      "x"
                    )}@biobot.io`,
                    contact_title: values["title"],
                    contact_phone: values["Phone"],
                    address_line_1: values["Address line 1"],
                    address_line_2: values["Address line 2"],
                    city: values["City"],
                    post_code: values["Post code"],
                    province: values["Province"],
                    country: values["Country"],
                  },
                })
                .then((res) => {
                  return this.props.actionLeadDelete(this.state.lead.id);
                })
                .then((res) => {
                  return this.props.actionLeadsGet();
                })
                .then((res) => {
                  this.setState({
                    loading: false,
                    visible: false,
                  });
                  message.success("Account created.");
                })
                .catch((err) => {
                  this.setState({
                    loading: false,
                  });
                  message.error("Error creating user.");
                });
            }}
            onFieldsChange={(values) => {
              if (values && values[0] && values[0].name[0] === "Country") {
                this.setState({
                  country: values[0].value,
                });
              }
            }}
            onFinishFailed={(err) => {
              console.log(err);
            }}
          >
            {this.state.lead.id && (
              <h1>{this.state.lead.lead_meta.organization_name}</h1>
            )}
            <Row gutter={30}>
              <Col sm={24}>
                <h2>Contact</h2>
              </Col>
              <Col sm={12}>
                <Form.Item
                  name="Name"
                  label="Name"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col sm={12}>
                <Form.Item
                  name="Title"
                  label="Job Title"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={30}>
              <Col sm={24}>
                <h2>Organization</h2>
              </Col>
              <Col sm={12}>
                <Form.Item
                  name="Organization"
                  label="Organization name"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col sm={12}>
                <Form.Item
                  name="Email"
                  label="Contact email"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={30}>
              <Col sm={24}>
                <h2>Billing address</h2>
              </Col>
              <Col sm={24}>
                <Form.Item
                  name="Address line 1"
                  label="Billing address line 1"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col sm={24}>
                <Form.Item name="Address line 2" label="Billing address line 2">
                  <Input />
                </Form.Item>
              </Col>
              <Col sm={12}>
                <Form.Item
                  name="City"
                  label="City"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col sm={12}>
                {this.state.country === "USA" && (
                  <Form.Item
                    label={"State / Province"}
                    name={`Province`}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0 ||
                        option.props.value
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder="State"
                    >
                      {usAdmin.map((region, index) => (
                        <Option key={index} value={region.abbreviation}>
                          {region.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
                {this.state.country === "Canada" && (
                  <Form.Item
                    label={"State / Province"}
                    name={`Province`}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0 ||
                        option.props.value
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder="Province"
                    >
                      {caAdmin.map((region, index) => (
                        <Option key={index} value={region.abbreviation}>
                          {region.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </Col>
              <Col sm={12}>
                <Form.Item
                  name="Post code"
                  label="Zip / Post code"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col sm={12}>
                <Form.Item
                  name="Phone"
                  label="Phone"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col sm={12}>
                <Form.Item
                  label="Country"
                  name={`Country`}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select>
                    <Option value="USA">USA</Option>
                    <Option value="Canada">Canada</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={30}>
              <Col sm={24}>
                <h2>Account classification</h2>
              </Col>
              <Col sm={12}>
                <Form.Item
                  name="Industry"
                  label="Account industry"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col sm={24}>
                <Button type="primary" htmlType="submit">
                  Activate
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    );
  }
}

const LeadFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadForm);
export default withRouter(LeadFormContainer);
