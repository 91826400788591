import produce from "immer";

const getDefaultState = () => {
  return [];
};

const kits = (state = getDefaultState(), action) => {
  let nextState;
  switch (action.type) {
    case "SITES_GET":
      nextState = produce(state, (draftState) => {
        const sites = [];
        action.payload.data.forEach((site) => {
          sites.push({
            ...site,
          });
        });
        return sites;
      });
      return nextState;
    default:
      return state;
  }
};

export default kits;
