import produce from "immer";

const getDefaultState = () => {
  return [];
};

const kits = (state = getDefaultState(), action) => {
  let nextState;
  switch (action.type) {
    case "KITS_GET":
      nextState = produce(state, (draftState) => {
        const kits = [];
        action.payload.data.forEach((kit) => {
          kits.push({
            ...kit,
            ...kit.KitMetaData,
          });
        });
        return kits;
      });
      return nextState;
    default:
      return state;
  }
};

export default kits;
