import { Component } from "react";
import { withRouter } from "react-router-dom";
import ReactGA from "react-ga";
ReactGA.initialize("UA-124209075-2");

class Track extends Component {
  componentDidMount() {
    ReactGA.pageview(this.props.location.pathname);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      ReactGA.pageview(this.props.location.pathname);
    }
  }

  render() {
    return null;
  }
}

export default withRouter(Track);
