export const getAccountData = (props) => {
  return new Promise(function (resolve, reject) {
    if (props.organization && props.organization.id) {
      // don't fetch new data
      resolve();
    } else {
      // fetch data
      props
        .actionUserGet()
        .then((res) => {
          resolve();
        })
        .catch((err) => {
          reject();
        });
    }
  });
};
