import axios from "axios";
import moment from "moment";

export const actionUserAuth = function (data) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}identity?ts=${moment().format("x")}`,
    {
      username: data.username,
      password: data.password,
    }
  );
  return {
    type: "USER_AUTH",
    payload: request,
  };
};

export const actionForceChangePassword = function (data) {
  const request = axios.post(
    `${
      process.env.REACT_APP_API_URL
    }identity/password/change?ts=${moment().format("x")}`,
    {
      username: data.username,
      password: data.password,
      newPassword: data.newPassword,
    }
  );
  return {
    type: "USER_FORCE_CHANGE_PASSWORD",
    payload: request,
  };
};

export const actionResendTemporaryPassword = function (email) {
  const request = axios.put(
    `${process.env.REACT_APP_API_URL}identity/resend/temp-password/${email}`,
    {}
  );
  return {
    type: "USER_RESEND_TEMP_PASSWORD",
    payload: request,
  };
};

export const actionUserSignOut = function (data) {
  return {
    type: "USER_SIGN_OUT",
    data: data,
  };
};

export const actionUserGet = function (email) {
  if (!email) {
    email = localStorage.getItem("email");
  }
  const request = axios.get(
    `${process.env.REACT_APP_API_URL}identity/${email}`
  );
  return {
    type: "USER_GET",
    payload: request,
  };
};

export const actionUserTokenGet = function () {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}identity/account/user/token`,
    {
      token: localStorage.getItem("token"),
    }
  );
  return {
    type: "USER_TOKEN_GET",
    payload: request,
  };
};

export const actionUserStrapiGet = function () {
  const email = localStorage.getItem("email");
  const request = axios.get(
    `${process.env.REACT_APP_API_URL}users/?email=${email}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return {
    type: "USER_STRAPI_GET",
    payload: request,
  };
};

export const actionLeadSave = function (data) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}leads?ts=${moment().format("x")}`,
    data
  );
  return {
    type: "LEAD_SAVE",
    payload: request,
  };
};

export const actionLeadAdd = function (data) {
  return {
    type: "LEAD_ADD",
    data: data,
  };
};

export const actionUserIpGet = function () {
  const request = axios.get(
    `https://geolocation-db.com/json/1a811210-241d-11eb-b7a9-293dae7a95e1`
  );
  return {
    type: "LEAD_USER_IP_GET",
    payload: request,
  };
};

export const actionLeadIpGet = function () {
  const request = axios.get(
    `https://geolocation-db.com/json/1a811210-241d-11eb-b7a9-293dae7a95e1`
  );
  return {
    type: "LEAD_USER_IP_GET",
    payload: request,
  };
};

export const actionLeadIpExistsGet = function (ip) {
  const request = axios.get(
    `${process.env.REACT_APP_API_URL}leads/?ip_address=${ip}`
  );
  return {
    type: "LEAD_IP_EXISTS_GET",
    payload: request,
  };
};

export const actionLeadsGet = function (data) {
  const request = axios.get(`${process.env.REACT_APP_API_URL}leads`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return {
    type: "LEADS_GET",
    payload: request,
  };
};

export const actionOrganizationGet = function (data) {
  const request = axios.get(
    `${process.env.REACT_APP_API_URL}organizations/${data.id}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return {
    type: "ORGANIZATION_GET",
    payload: request,
  };
};

export const actionAccountsGet = function () {
  const request = axios.get(`${process.env.REACT_APP_API_URL}identity/all`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return {
    type: "ACCOUNTS_GET",
    payload: request,
  };
};

export const actionOrganizationSet = function (data) {
  return {
    type: "ORGANIZATION_SET",
    data: data,
  };
};

export const actionOrganizationAdd = function (data) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}organizations`,
    data,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return {
    type: "ORGANIZATION_ADD",
    payload: request,
  };
};

export const actionSiteAdd = function (data) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}sites?ts=${moment().format("x")}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return {
    type: "SITE_ADD",
    payload: request,
  };
};

export const actionSiteEdit = function (data) {
  const request = axios.put(
    `${process.env.REACT_APP_API_URL}sites/${data.id}?ts=${moment().format(
      "x"
    )}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return {
    type: "SITE_EDIT",
    payload: request,
  };
};

export const actionProductsGet = function (accountType) {
  const request = axios.get(
    `${process.env.REACT_APP_API_URL}products/?product_account_type=${accountType}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return {
    type: "PRODUCTS_GET",
    payload: request,
  };
};

export const actionOrderAdd = function (data) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}orders?ts=${moment().format("x")}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return {
    type: "ORDER_ADD",
    payload: request,
  };
};

export const actionSiteSave = function (data) {
  const request = axios.put(
    `${process.env.REACT_APP_API_URL}sites/${data.id}?ts=${moment().format(
      "x"
    )}`,
    data.record
  );
  return {
    type: "SITE_SAVE",
    payload: request,
  };
};

export const actionSiteDelete = function (data) {
  return {
    type: "SITE_DELETE",
    data: data,
  };
};

export const actionUsersGet = function (accountId) {
  const request = axios.get(
    `${process.env.REACT_APP_API_URL}identity/account/users/${accountId}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return {
    type: "USERS_GET",
    payload: request,
  };
};

export const actionUserEdit = function (data) {
  const request = axios.put(
    `${process.env.REACT_APP_API_URL}identity/account/user/${data.id}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return {
    type: "USER_EDIT",
    payload: request,
  };
};

export const actionUserAdd = function (data) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}identity/account/user/add`,
    data,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return {
    type: "USER_ADD",
    payload: request,
  };
};

export const actionAccountUserGet = function (id) {
  const request = axios.get(
    `${process.env.REACT_APP_API_URL}identity/account/user/${id}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return {
    type: "USER_ACCOUNT_GET",
    payload: request,
  };
};

export const actionRolesGet = function (id) {
  const request = axios.get(
    `${process.env.REACT_APP_API_URL}identity/account/roles`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return {
    type: "ROLES_GET",
    payload: request,
  };
};

export const actionUserRolesEdit = function (data) {
  const request = axios.put(
    `${process.env.REACT_APP_API_URL}identity/account/user/roles/${data.userId}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return {
    type: "USER_EDIT",
    payload: request,
  };
};

export const actionAccountDelete = function (data) {
  const request = axios.delete(
    `${process.env.REACT_APP_API_URL}identity/account/${data.id}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return {
    type: "ACCOUNT_DELETE",
    payload: request,
  };
};

export const actionWelcomeEmailSend = function (data) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}notifications`,
    data,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return {
    type: "USER_WELCOME_EMAIL_SEND",
    payload: request,
  };
};

export const actionGeocodeAddress = function (address) {
  const request = axios.get(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${address}.json?access_token=${process.env.REACT_APP_MAPBOX_API_KEY}`
  );
  return {
    type: "ADDRESS_GEOCODE",
    payload: request,
  };
};

export const actionAddressSave = function (data) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}shipping-locations?ts=${moment().format(
      "x"
    )}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return {
    type: "ADDRESS_SAVE",
    payload: request,
  };
};

export const actionLeadGet = function (id) {
  const request = axios.get(
    `${process.env.REACT_APP_API_URL}leads/${id}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return {
    type: "LEAD_GET",
    payload: request,
  };
};

export const actionLeadDelete = function (id) {
  const request = axios.delete(
    `${process.env.REACT_APP_API_URL}leads/${id}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return {
    type: "LEAD_DELETE",
    payload: request,
  };
};

export const actionUserAccountsEdit = function (data) {
  const request = axios.put(
    `${process.env.REACT_APP_API_URL}identity/access/user/grant?ts=${moment().format(
      "x"
    )}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return {
    type: "USER_ACCOUNTS_EDIT",
    payload: request,
  };
};
