import React, { useState } from "react";
import { Modal, Form, Button, Input, message, Select, Row, Col } from "antd";
import moment from "moment";
import usAdmin from "../../data/us/admin.json";
import caAdmin from "../../data/ca/admin.json";
const { Option } = Select;

export const AccountAddModal = (props) => {
  const formRef = React.createRef();
  const [country, setCountry] = useState("USA");
  return (
    <Modal
      title=""
      width={"80%"}
      footer={null}
      visible={props.visible}
      onCancel={props.handleCancel}
    >
      <Form
        ref={formRef}
        layout="vertical"
        onFinish={(values) => {
          props.loading(true);
          props
            .actionOrganizationAdd({
              organization_name: values["Account name"],
              account_type: "government",
              organization_meta: {
                contact_name: values["Name"],
                contact_email: `orderservice+${moment().format("x")}@biobot.io`,
                contact_title: values["title"],
                contact_phone: values["Phone"],
                address_line_1: values["Address line 1"],
                address_line_2: values["Address line 2"],
                city: values["City"],
                post_code: values["Post code"],
                province: values["Province"],
                country: values["Country"],
              },
            })
            .then((res) => {
              props.handleCancel();
              props.onChange();
              props.loading(false);
              message.success(`Account with id: ${res.data.id} was created.`);
            })
            .catch((err) => {
              props.loading(false);
              message.error("Error creating user.");
            });
        }}
        onFieldsChange={(values) => {
          if (values && values[0] && values[0].name[0] === "Country") {
            setCountry(values[0].value);
          }
        }}
        onFinishFailed={(err) => {
          console.log(err);
        }}
      >
        <h1>Add Account</h1>
        <Row gutter={30}>
          <Col sm={24}>
            <Form.Item
              name="Account name"
              label="Account name"
              rules={[{ required: true }]}
            >
              <Input placeholder={"My Wastewater Company"} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col sm={12}>
            <Form.Item
              name="Name"
              label="Admin user name"
              rules={[{ required: true }]}
            >
              <Input placeholder={"Jen Terry"} />
            </Form.Item>
          </Col>
          <Col sm={12}>
            <Form.Item
              name="Email"
              label="Admin user email"
              rules={[{ required: true }]}
            >
              <Input placeholder={"jen.terry@mywasterwatercompany.com"} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col sm={24}>
            <h2>Billing address</h2>
          </Col>
          <Col sm={24}>
            <Form.Item
              name="Address line 1"
              label="Billing address line 1"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col sm={24}>
            <Form.Item name="Address line 2" label="Billing address line 2">
              <Input />
            </Form.Item>
          </Col>
          <Col sm={12}>
            <Form.Item name="City" label="City" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col sm={12}>
            {country === "USA" && (
              <Form.Item
                label={"State / Province"}
                name={`Province`}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0 ||
                    option.props.value
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="State"
                >
                  {usAdmin.map((region, index) => (
                    <Option key={index} value={region.abbreviation}>
                      {region.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            {country === "Canada" && (
              <Form.Item
                label={"State / Province"}
                name={`Province`}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0 ||
                    option.props.value
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Province"
                >
                  {caAdmin.map((region, index) => (
                    <Option key={index} value={region.abbreviation}>
                      {region.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Col>
          <Col sm={12}>
            <Form.Item
              name="Post code"
              label="Zip / Post code"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col sm={12}>
            <Form.Item name="Phone" label="Phone" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col sm={12}>
            <Form.Item
              label="Country"
              name={`Country`}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select>
                <Option value="USA">USA</Option>
                <Option value="Canada">Canada</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col sm={24}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
