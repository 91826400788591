import produce from "immer";
import moment from "moment";
const getDefaultState = () => {
  return {};
};

const organization = (state = getDefaultState(), action) => {
  let nextState;
  switch (action.type) {
    case "ORGANIZATION_GET":
      nextState = produce(state, (draftState) => {
        const kits = [];
        action.payload.data.kits.forEach((kit) => {
          kits.push({
            ...kit,
            ...kit.kit_meta_data,
          });
        });
        const orders = [];
        action.payload.data.orders.forEach((order) => {
          orders.push(order);
        });
        const sites = [];
        action.payload.data.sites.forEach((site) => {
          sites.push(site);
        });
        const products = [];
        action.payload.data.products.forEach((product) => {
          products.push(product);
        });
        draftState.organization_name = action.payload.data.organization_name;
        draftState.id = action.payload.data.id;
        draftState.Users = action.payload.data.Users;
        draftState.sites = sites;
        draftState.orders = orders;
        draftState.products = products;
        draftState.kits = kits.sort(
          (a, b) =>
            moment(b.order_date).format("x") - moment(a.order_date).format("x")
        );
      });
      return nextState;
    case "ORGANIZATION_ADD":
      nextState = produce(state, (draftState) => action.payload.data);
      return nextState;
    case "SITE_ADD":
      nextState = produce(state, (draftState) => {
        draftState.Sites.push({
          id: action.payload.data.id,
          SiteName: action.payload.data.site_name,
        });
      });
      return nextState;
    case "SITE_EDIT":
      nextState = produce(state, (draftState) => {
        draftState.Sites.forEach((site) => {
          if (site.id === action.payload.data.id) {
            site.SiteName = action.payload.data.site_name;
          }
        });
      });
      return nextState;
    case "SITE_SAVE":
      return state;
    default:
      return state;
  }
};

export default organization;
