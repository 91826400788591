import produce from "immer";
import { message } from "antd";

const getDefaultState = () => {
  return {};
};

const user = (state = getDefaultState(), action) => {
  let nextState;
  switch (action.type) {
    case "USER_AUTH":
      if (action.payload.data && action.payload.data.accessToken) {
        localStorage.setItem("token", action.payload.data.accessToken);
        nextState = produce(state, (draftState) => {
          draftState.auth = true;
        });
      } else {
        message.error("Username or password incorrect");
        localStorage.removeItem("token");
        localStorage.removeItem("email");
        localStorage.removeItem("role");
      }
      return nextState;
    case "USER_SIGN_OUT":
      localStorage.removeItem("token");
      localStorage.removeItem("email");
      localStorage.removeItem("role");
      window.location = "/";
      return state;
    case "USER_TOKEN_GET":
      nextState = produce(state, (draftState) => {
        if (action.error && draftState.id) {
          localStorage.removeItem("token");
          localStorage.removeItem("email");
          localStorage.removeItem("role");
          window.location = "/";
        }
      });
      return state;
    case "USER_CREATE":
      if (action.error) {
        message.error(action.payload.response.data.data[0].messages[0].message);
        return state;
      } else {
        nextState = produce(state, (draftState) => {
          draftState.props = action.payload.data.user;
        });
        return nextState;
      }
    case "USER_GET":
      nextState = produce(state, (draftState) => action.payload.data);
      return nextState;
    case "USER_STRAPI_GET":
      if (action.error) {
        localStorage.removeItem("token");
        localStorage.removeItem("id");
        localStorage.removeItem("email");
        localStorage.removeItem("role");
        window.location = "/";
        return state;
      } else {
        nextState = produce(state, (draftState) => {
          draftState.auth = true;
          draftState.props = action.payload.data[0];
        });
        return nextState;
      }
    default:
      return state;
  }
};

export default user;
