import React, { Component } from "react";
import { Provider } from "react-redux";
import ReduxPromise from "redux-promise";
import { createStore, applyMiddleware } from "redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import reducers from "./reducers";
import Login from "./containers/Login/Login";
import Users from "./containers/Users/Users";
import UserForm from "./containers/UserForm/UserForm";
import Accounts from "./containers/Accounts/Accounts";
import Account from "./containers/Account/Account";
import Leads from "./containers/Leads/Leads";
import LeadForm from "./containers/LeadForm/LeadForm";
import Track from "./components/Track/Track";
import Auth from "./components/Auth/Auth";
import "./App.css";
import "antd/dist/antd.css";
import ReactGA from "react-ga";
ReactGA.initialize("UA-124209075-2");

const store = createStore(reducers, applyMiddleware(ReduxPromise));
window.store = store;

class App extends Component {
  render() {
    return (
      <div className="App">
        <Provider store={store}>
          <Router>
            <Switch>
              <Route exact path="/" render={(props) => <Login />} />
              <Route
                exact
                path="/user/:id"
                render={(props) => (
                  <>
                    <Auth />
                    <UserForm />
                    <Users />
                  </>
                )}
              />
              <Route
                exact
                path="/account"
                render={(props) => (
                  <>
                    <Auth />
                    <Account />
                  </>
                )}
              />
              <Route
                exact
                path="/users"
                render={(props) => (
                  <>
                    <Auth />
                    <Users />
                  </>
                )}
              />
              <Route
                exact
                path="/accounts"
                render={(props) => (
                  <>
                    <Auth />
                    <Accounts />
                  </>
                )}
              />
              <Route
                exact
                path="/leads"
                render={(props) => (
                  <>
                    <Auth />
                    <Leads />
                  </>
                )}
              />
              <Route
                exact
                path="/lead/:id"
                render={(props) => (
                  <>
                    <Auth />
                    <LeadForm />
                    <Leads />
                  </>
                )}
              />
            </Switch>
            <Track />
          </Router>
        </Provider>
      </div>
    );
  }
}

export default App;
