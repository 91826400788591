import produce from "immer";

const getDefaultState = () => {
  return [];
};

const accountUser = (state = getDefaultState(), action) => {
  let nextState;
  switch (action.type) {
    case "USER_ACCOUNT_GET":
      nextState = produce(state, (draftState) => action.payload.data);
      return nextState;
    default:
      return state;
  }
};

export default accountUser;
