import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Dropdown, Menu } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { actionUserGet, actionUserSignOut } from "../../actions/";
import "./Nav.css";

const mapStateToProps = (state, ownProps) => {
  if (state) {
    return state;
  } else {
    return null;
  }
};

const mapDispatchToProps = {
  actionUserGet,
  actionUserSignOut,
};

class Nav extends Component {
  state = {
    loggedIn: localStorage.getItem("token"),
  };

  checkIfToken() {
    const token = localStorage.getItem("token");
    if (token) {
      return true;
    }
    return false;
  }

  render() {
    return (
      <div className="nav" id="nav">
        {this.props.link && (
          <Link className="brand" to={this.props.link}>
            Biobot
          </Link>
        )}
        {this.props.url && (
          <a target="blank" className="brand" href={this.props.url}>
            Biobot
          </a>
        )}
        {this.checkIfToken() && (
          <Dropdown
            className="user-menu"
            trigger={"click"}
            overlay={
              <Menu>
                <Menu.Item>
                  <a
                    href="#sign-out"
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.actionUserSignOut();
                      this.props.history.push("/");
                    }}
                  >
                    Sign Out
                  </a>
                </Menu.Item>
              </Menu>
            }
          >
            <a
              className="ant-dropdown-link"
              href="#setting"
              onClick={(e) => e.preventDefault()}
            >
              <SettingOutlined />
            </a>
          </Dropdown>
        )}
      </div>
    );
  }
}

const NavContainer = connect(mapStateToProps, mapDispatchToProps)(Nav);

export default withRouter(NavContainer);
