import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import Nav from "../Nav/Nav";
import MainMenu from "../../components/Menu/Menu";
import qs from "qs";
import moment from "moment";
import { Row, Col, Button, message, Popconfirm, Select, Table } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { UserAddModal } from "./components.js";
import {
  actionUsersGet,
  actionUserAdd,
  actionUserEdit,
  actionAccountsGet,
  actionWelcomeEmailSend,
} from "../../actions/";
import "./Users.css";
import { usePrevious } from "../../Utils";
import Loader from "../../components/Loader/Loader";
const { Option } = Select;

const mapStateToProps = (state, ownProps) => {
  if (state) {
    return state;
  } else {
    return null;
  }
};

const mapDispatchToProps = {
  actionUsersGet,
  actionUserAdd,
  actionUserEdit,
  actionAccountsGet,
  actionWelcomeEmailSend,
};

export function Users(props) {
  const orgId = localStorage.getItem("orgId");
  const orgName = localStorage.getItem("orgName");

  const initialAccountId =
    qs.parse(props.location.search, { ignoreQueryPrefix: true }).account_id ||
    orgId;
  const [loading, setLoading] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [accountId, setAccountId] = useState(initialAccountId);
  const [accountName, setAccountName] = useState(orgName);

  const getData = useCallback(() => {
    setLoading(true);
    props
      .actionUsersGet(accountId)
      .then(() => props.actionAccountsGet())
      .then(() => setLoading(false))
      .catch((err) => {
        setLoading(false);
      });
  }, [accountId, props]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getData(), []);

  const previousLocation = usePrevious(props.location.pathname);
  useEffect(() => {
    if (previousLocation !== props.location.pathname) {
      if (props.location.search) {
        setAccountId(
          qs.parse(props.location.search, {
            ignoreQueryPrefix: true,
          }).account_id
        );
      }
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.pathname, previousLocation]);

  if (loading) {
    return <Loader />;
  }

  const getColumns = () => [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text, result) => (
        <Link to={`/user/${result.id}${props.location.search}`}>{text}</Link>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Created",
      dataIndex: "created_date",
      key: "created",
      render: (text) => moment(text).format("MM-DD-YYYY"),
    },
    {
      title: "Active",
      dataIndex: "active",
      key: "active",
      render: (text, result) => {
        if (result.active === true) {
          return "True";
        }
        return "False";
      },
    },
    {
      title: "",
      dataIndex: "delete",
      key: "delete",
      render: (text, result) => (
        <Popconfirm
          title="Are you sure to disable this user?"
          onConfirm={() => {
            props
              .actionUserEdit({
                id: result.id,
                value: {
                  active: false,
                },
              })
              .then((res) => {
                message.success("User account disabled.");
                getData();
              })
              .catch((err) => {
                message.error("Error disabling user account.");
              });
          }}
          okText="Yes"
          cancelText="No"
        >
          <DeleteOutlined />
        </Popconfirm>
      ),
    },
  ];

  return (
    <div>
      <Nav url={"https://www.biobot.io/"} />
      <MainMenu {...props} />
      <div className="page">
        <Row gutter={20}>
          <Col sm={24}>
            <h2>Users</h2>
          </Col>
        </Row>
        <div className="view-controls">
          <Row>
            <Col sm={12}>
              {props.accounts.length > 0 && (
                <>
                  <label>Filter users by account</label>
                  <Select
                    showSearch
                    disabled
                    optionFilterProp="children"
                    style={{ width: "300px" }}
                    value={Number(accountId)}
                    onChange={(value, e) => {
                      setAccountId(value);
                      setAccountName(e.name);
                      props.history.push({
                        search: `?account_id=${value}`,
                      });
                      setTimeout(() => {
                        getData();
                      }, 1000);
                    }}
                  >
                    {props.accounts.map((item) => (
                      <Option value={item.id} name={item.name} key={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </>
              )}
            </Col>
            <Col sm={12}>
              <div className="align-right">
                <Button onClick={() => setShowAddModal(true)} type="primary">
                  Add User
                </Button>
              </div>
            </Col>
          </Row>
        </div>
        <Table
          rowKey={(obj) => obj.id}
          columns={getColumns()}
          dataSource={props.users}
          pagination={{
            defaultPageSize: 20,
            position: ["topRight", "bottomRight"],
          }}
        />
        <UserAddModal
          visible={showAddModal}
          accountId={accountId}
          accountName={accountName}
          onChange={() => getData()}
          handleCancel={() => setShowAddModal(false)}
          {...props}
        />
      </div>
    </div>
  );
}

const UsersContainer = connect(mapStateToProps, mapDispatchToProps)(Users);
export default withRouter(UsersContainer);
