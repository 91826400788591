import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import {
  LoginEmail,
  LoginPassword,
  ForceChangePassword,
} from "./components.js";
import Nav from "../Nav/Nav";
import {
  actionUserAuth,
  actionUserGet,
  actionUserStrapiGet,
  actionForceChangePassword,
  actionResendTemporaryPassword,
  actionRolesGet,
} from "../../actions/";
import "./Login.css";

const mapStateToProps = (state, ownProps) => {
  if (state) {
    return state;
  } else {
    return null;
  }
};

const mapDispatchToProps = {
  actionUserAuth,
  actionUserStrapiGet,
  actionForceChangePassword,
  actionResendTemporaryPassword,
  actionUserGet,
  actionRolesGet,
};

class Login extends Component {
  state = {
    username: "",
    password: "",
    loading: false,
    allowLogin: false,
    changePassword: false,
  };

  componentDidMount() {
    // if user is logged in
    const token = localStorage.getItem("token");
    if (token) {
      this.props.history.push("/accounts");
    }
  }

  render() {
    return (
      <div>
        {this.state.loading && <Loader />}
        <Nav link="/" />
        <div className="login-form">
          <div className="login-image"></div>
          <h1>Sign in</h1>
          <LoginEmail _self={this} {...this.props} />
          <LoginPassword _self={this} {...this.props} />
          <ForceChangePassword _self={this} {...this.props} />
        </div>
        <div className="footer">
          &copy; 2021 Biobot Analytics, Inc. | Cambridge, MA
        </div>
      </div>
    );
  }
}

const LoginContainer = connect(mapStateToProps, mapDispatchToProps)(Login);

export default withRouter(LoginContainer);
