import produce from "immer";

const getDefaultState = () => {
  return [];
};

const users = (state = getDefaultState(), action) => {
  let nextState;
  switch (action.type) {
    case "USERS_GET":
      nextState = produce(state, (draftState) => {
        if (action.error) {
          // return an empty array
          return [];
        } else {
          const accountUsers = action.payload.data.users.sort(
            (a, b) => b.active - a.active
          );
          return accountUsers;
        }
      });
      return nextState;
    default:
      return state;
  }
};

export default users;
