import produce from "immer";

const getDefaultState = () => {
  return [];
};

const products = (state = getDefaultState(), action) => {
  let nextState;
  switch (action.type) {
    case "PRODUCTS_GET":
      nextState = produce(state, (draftState) => {
        if (action.error) {
          localStorage.removeItem("token");
          localStorage.removeItem("id");
          localStorage.removeItem("email");
          localStorage.removeItem("role");
          window.location = "/";
        } else {
          return action.payload.data;
        }
      });
      return nextState;
    default:
      return state;
  }
};

export default products;
