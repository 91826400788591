import React from "react";
import { Modal, Form, Button, Input, message, Row, Col } from "antd";
import { getTempPassword } from "../Accounts/helpers";

export const UserAddModal = (props) => {
  return (
    <Modal
      title=""
      width={"80%"}
      footer={null}
      visible={props.visible}
      onCancel={props.handleCancel}
    >
      <Form
        layout="vertical"
        onFinish={(values) => {
          const tempPassword = getTempPassword();
          props
            .actionUserAdd({
              action: "SUPPRESS",
              account: {
                name: props.accountName,
              },
              temporaryPassword: tempPassword,
              user: {
                name: values["Name"],
                email: values["Email"],
                email_verified: true,
                active: true,
              },
            })
            .then((res) => {
              return props.actionWelcomeEmailSend({
                notification_config: {
                  recipients: [values["Email"]],
                  subject: "Your Biobot Account",
                  text: `
              Hi ${values["Name"]},
              You can now sign in to the ${props.accountName} Biobot account at {APP_URL}
              using the email: ${values["Email"]}
              - The Biobot Team
              `,
                  html: `
              <p>Hi ${values["Name"]},</p>
              <p>You can now sign in to the <b>${props.accountName}</b> Biobot account at {APP_URL}
              using the email:</p>
              <p>${values["Email"]}</p>
              <p>Team Biobot</p>
              `,
                },
              });
            })
            .then((res) => {
              props.handleCancel();
              props.onChange();
              message.success("User created. A temporary password was sent.");
            })
            .catch((err) => {
              message.error("Error creating user.");
            });
        }}
        onFinishFailed={(err) => {
          console.log(err);
        }}
      >
        <h1>Add User</h1>
        <Row gutter={30}>
          <Col sm={12}>
            <Form.Item name="Name" label="Name" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col sm={12}>
            <Form.Item name="Email" label="Email" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col sm={24}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
