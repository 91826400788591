import produce from "immer";

const getDefaultState = () => {
  return [];
};

const orders = (state = getDefaultState(), action) => {
  let nextState;
  switch (action.type) {
    case "ORDERS_GET":
      nextState = produce(state, (draftState) => {
        const orders = [];
        action.payload.data.forEach((order) => {
          orders.push({
            ...order,
          });
        });
        return orders;
      });
      return nextState;
    default:
      return state;
  }
};

export default orders;
