import { combineReducers } from "redux";
import user from "./reducerUser.js";
import organization from "./reducerOrganization.js";
import products from "./reducerProducts.js";
import kits from "./reducerKits.js";
import orders from "./reducerOrders.js";
import accounts from "./reducerAccounts.js";
import sites from "./reducerSites.js";
import leads from "./reducerLeads.js";
import users from "./reducerUsers.js";
import roles from "./reducerRoles.js";
import accountUser from "./reducerAccountUser.js";

export default combineReducers({
  user,
  organization,
  products,
  kits,
  orders,
  accounts,
  sites,
  leads,
  users,
  accountUser,
  roles,
});
