import React from "react";
import { Link, withRouter } from "react-router-dom";
import { RightOutlined } from "@ant-design/icons";
import menuConfig from "../../configs/menu.json";
import "./Menu.css";

function Menu(props) {
  return (
    <div className="main-menu">
      <div className="content">
        <ul className="primary-menu">
          {menuConfig.map((item, index) => (
            <li key={index} className={`menu-item-${index}`}>
              <Link to={item.route}>
                {item.label} <RightOutlined />
              </Link>
              {/*}
           { item.children &&
            <ul className="child-menu">
            { item.children.map((child) =>
              <li>
             <Link to={ child.route }>
                { child.label }
             </Link>
             </li>
           )}
            </ul>
           }
           {*/}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default withRouter(Menu);
