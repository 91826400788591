import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  actionUserTokenGet,
  actionUserGet,
  actionUserSignOut,
} from "../../actions/";

const mapStateToProps = (state, ownProps) => {
  if (state) {
    return state;
  } else {
    return null;
  }
};

const mapDispatchToProps = {
  actionUserTokenGet,
  actionUserGet,
  actionUserSignOut,
};

class Auth extends Component {
  componentDidMount() {
    const email = localStorage.getItem("email");
    if (!email) {
      this.props.actionUserSignOut();
    }
    if (email) {
      this.props
        .actionUserGet()
        .then((res) => {
          this.props.actionUserTokenGet();
        })
        .catch((err) => {
          this.props.actionUserSignOut();
        });
    }
  }

  componentDidUpdate(prevProps) {
    const email = localStorage.getItem("email");
    if (!email) {
      this.props.actionUserSignOut();
    }
    if (email && prevProps.location.pathname !== this.props.location.pathname) {
      this.props
        .actionUserGet()
        .then((res) => {
          this.props.actionUserTokenGet();
        })
        .catch((err) => {
          this.props.actionUserSignOut();
        });
    }
  }

  render() {
    return null;
  }
}

const AuthContainer = connect(mapStateToProps, mapDispatchToProps)(Auth);
export default withRouter(AuthContainer);
