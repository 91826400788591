import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Nav from "../Nav/Nav";
import Loader from "../../components/Loader/Loader";
import MainMenu from "../../components/Menu/Menu";
import { Row, Col, Button, Table, Popconfirm, message } from "antd";
import { LoadingOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  actionAccountsGet,
  actionWelcomeEmailSend,
  actionUserAdd,
  actionOrganizationAdd,
  actionAccountDelete,
} from "../../actions/index.js";
import { AccountAddModal } from "./components.js";

import "./Accounts.css";

const mapStateToProps = (state, ownProps) => {
  if (state) {
    return state;
  } else {
    return null;
  }
};

const mapDispatchToProps = {
  actionAccountsGet,
  actionOrganizationAdd,
  actionUserAdd,
  actionAccountDelete,
  actionWelcomeEmailSend,
};

class Accounts extends Component {
  state = {
    loading: false,
    showAddModal: false,
  };

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.getData();
    }
  }

  getData() {
    this.props.actionAccountsGet();
  }

  getColumns() {
    return [
      {
        title: "Name",
        dataIndex: ["name"],
        key: "account_name",
      },
      {
        title: "Account ID",
        dataIndex: ["id"],
        key: "id",
      },
      {
        title: "",
        dataIndex: ["delete"],
        key: "delete",
        render: (text, result) => {
          return (
            <Popconfirm
              title="Are you sure to delete this account?"
              onConfirm={() => {
                this.props
                  .actionAccountDelete({
                    id: result.id,
                  })
                  .then((res) => {
                    message.success("Account deleted.");
                    this.getData();
                  })
                  .catch((err) => {
                    message.error("Error deleting account.");
                  });
              }}
              okText="Yes"
              cancelText="No"
            >
              <DeleteOutlined />
            </Popconfirm>
          );
        },
      },
    ];
  }

  render() {
    return (
      <div>
        <Nav url={"https://www.biobot.io/"} />
        <MainMenu {...this.props} />
        {this.state.loading && <Loader />}
        <div className="page">
          <Row gutter={20}>
            <Col sm={24}>
              <h2>Accounts</h2>
              {this.state.loading && (
                <div className="view-loading">
                  <LoadingOutlined />
                </div>
              )}
            </Col>
          </Row>
          <div className="view-controls">
            <Row>
              <Col sm={24}>
                <div className="align-right">
                  <Button
                    onClick={() => {
                      this.setState({
                        showAddModal: true,
                      });
                    }}
                    type="primary"
                  >
                    Add Account
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
          <Table
            columns={this.getColumns()}
            dataSource={this.props.accounts}
            rowKey={(record) => record.id}
            pagination={{
              defaultPageSize: 50,
              position: ["topRight", "bottomRight"],
            }}
          />
          <AccountAddModal
            visible={this.state.showAddModal}
            loading={(value) => {
              this.setState({
                loading: value,
              });
            }}
            onChange={() => {
              this.getData();
            }}
            handleCancel={() => {
              this.setState({
                showAddModal: false,
              });
            }}
            {...this.props}
          />
        </div>
      </div>
    );
  }
}

const AccountsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Accounts);
export default withRouter(AccountsContainer);
