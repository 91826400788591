import React, { useState } from "react";
import { Input, Button, message } from "antd";

export const LoginEmail = (props) => {
  return (
    <>
      <div className="ui-field">
        <label>Username</label>
        <Input
          size="large"
          placeholder="Email address"
          name="email"
          onChange={(e) => {
            props._self.setState({
              username: e.target.value,
            });
          }}
        />
      </div>
      {props._self.state.changePassword === false &&
        props._self.state.allowLogin === false && (
          <Button
            style={{ marginTop: "10px" }}
            type="primary"
            size="large"
            onClick={() => {
              if (props._self.state.username) {
                props.actionUserGet(props._self.state.username).then((res) => {
                  localStorage.setItem(
                    "orgId",
                    res.payload.data.accounts[0].id
                  );
                  localStorage.setItem(
                    "orgName",
                    res.payload.data.accounts[0].name
                  );
                  if (res.payload.data.state === "FORCE_CHANGE_PASSWORD") {
                    message.warning(
                      "Check your email and enter the temporary password."
                    );
                    // send temporary password to user
                    props.actionResendTemporaryPassword(
                      props._self.state.username
                    );
                    props._self.setState({ changePassword: true });
                  } else {
                    props._self.setState({ allowLogin: true });
                  }
                });
              }
            }}
          >
            Submit
          </Button>
        )}
    </>
  );
};

export const LoginPassword = (props) => {
  const [password, setPassword] = useState();
  return (
    props._self.state.allowLogin && (
      <>
        <div className="ui-field">
          <label>Password</label>
          <Input.Password
            size="large"
            name="password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </div>
        <Button
          size="large"
          type="primary"
          onClick={() => {
            if (props._self.state.username && password) {
              props._self.setState({
                loading: true,
              });
              props
                .actionUserAuth({
                  username: props._self.state.username,
                  password: password,
                })
                .then((res) => {
                  localStorage.setItem("email", props._self.state.username);
                  props.history.push("/accounts");
                })
                .catch((err) => {
                  props._self.setState({
                    loading: false,
                  });
                });
            } else {
              message.error("Username or password incorrect");
            }
          }}
        >
          Submit
        </Button>
      </>
    )
  );
};

export const ForceChangePassword = (props) => {
  const [tempPassword, setTempPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [confirmNewPassword, setConfirmNewPassword] = useState();
  return (
    props._self.state.changePassword && (
      <>
        <div className="ui-field">
          <label>Temporary Password</label>
          <Input.Password
            size="large"
            onChange={(e) => {
              setTempPassword(e.target.value);
            }}
          />
        </div>
        <div className="ui-field">
          <label>New Password</label>
          <Input.Password
            size="large"
            onChange={(e) => {
              setNewPassword(e.target.value);
            }}
          />
        </div>
        <div className="ui-field">
          <label>Confirm New Password</label>
          <Input.Password
            size="large"
            onChange={(e) => {
              setConfirmNewPassword(e.target.value);
            }}
          />
        </div>
        <Button
          size="large"
          type="primary"
          onClick={() => {
            if (
              props._self.state.username &&
              tempPassword &&
              newPassword &&
              newPassword === confirmNewPassword
            ) {
              props
                .actionForceChangePassword({
                  username: props._self.state.username,
                  password: tempPassword,
                  newPassword: newPassword,
                })
                .then((res) => {
                  props._self.setState({
                    allowLogin: true,
                    changePassword: false,
                  });
                })
                .catch((err) => {
                  message.error(err.response.data.message);
                });
            } else {
              message.error("Passwords do not match.");
            }
          }}
        >
          Submit
        </Button>
      </>
    )
  );
};
